import React from 'react'
import {Grid, Autocomplete} from '@mui/material'
import CuiNumberFormat from 'src/components/custom/CuiNumberFormat'
import useAutosave from 'src/hooks/useAutosave'
import {SaveType} from 'src/context/Autosave'
import ProjectBilling from 'src/entities/ProjectBilling'
import {SplitInvoiceBy} from 'src/entities/BillingInfo'
import config from 'src/config'
import {getEnumOption} from 'src/utils/enumHelper'
import {getSplitByText} from '../BillingInfoFields'
import CuiTextField from 'src/components/custom/CuiTextField'
import Invoice, {getAllInvoicesAmount} from 'src/entities/Invoice'

interface InvoicesDetailsFooterProps {
  projectBilling: ProjectBilling
  onChangeBillingInfo: (key: string, data: any) => void
  invoices: Invoice[]
  disabled?: boolean
}

export default function InvoicesDetailsFooter({
  projectBilling,
  onChangeBillingInfo,
  invoices,
  disabled
}: InvoicesDetailsFooterProps) {
  const url = `${config.apiUrl}/projects/billing`
  useAutosave<ProjectBilling>({
    url: url,
    dataToSave: projectBilling,
    type: SaveType.Project,
    id: projectBilling?.id,
    delayToSaveInMs: 5000
  })

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid container item xs={6} spacing={2}>
        {invoices.length > 1 && (
          <>
            <Grid item xs={6} textAlign="right">
              Total Invoices Amount:
            </Grid>
            <Grid item xs={6} sx={{mb: 2}}>
              <CuiNumberFormat
                value={getAllInvoicesAmount(invoices.filter(x => x.isSelected))}
                displayType={'text'}
                addPrefix
              />
            </Grid>
          </>
        )}
        <Grid item xs={6} textAlign="right">
          Split Invoice By
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={
              getEnumOption(SplitInvoiceBy).find(
                x => x === projectBilling?.billingInfo?.splitInvoiceBy
              ) || null
            }
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                onChangeBillingInfo('splitInvoiceBy', newValue)
              }
            }}
            options={getEnumOption(SplitInvoiceBy)}
            getOptionLabel={t => getSplitByText(t)}
            isOptionEqualToValue={(option, value) => {
              return option === value
            }}
            includeInputInList
            renderInput={params => (
              <CuiTextField {...params} variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={6} textAlign="right">
          Global Rate
        </Grid>
        <Grid item xs={6}>
          <CuiNumberFormat
            variant="outlined"
            disabled={disabled}
            value={projectBilling?.billingInfo?.globalRate || ''}
            addPrefix
            onChange={(e: any) =>
              onChangeBillingInfo('globalRate', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6} textAlign="right">
          Discount
        </Grid>
        <Grid item xs={6}>
          <CuiNumberFormat
            variant="outlined"
            disabled={disabled}
            value={projectBilling?.billingInfo?.discount || ''}
            addPrefix
            onChange={(e: any) =>
              onChangeBillingInfo('discount', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={6} textAlign="right">
          Discount Percent
        </Grid>
        <Grid item xs={6}>
          <CuiNumberFormat
            disabled={disabled}
            variant="outlined"
            value={projectBilling?.billingInfo?.discountPercent || ''}
            addSuffix
            onChange={(e: any) =>
              onChangeBillingInfo('discountPercent', e.target.value)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
